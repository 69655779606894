<template>
  <el-container>
    <main class="page__container">
      <div class="container">
        <h2>Отчет-сравнение состояния камер
          <el-button
            v-if="!loading"
            type="success"
            size="mini"
            plain
            :disabled="isRebootButtonDisabled || !report.is_report_accessible"
            @click="handleRebootCamerasButton"
          >
            Перезагрузить камеры <span v-if="rebootCountdown > 0">({{ rebootCountdown }})</span>
          </el-button>
          <el-progress
            v-if="loading"
            :percentage="progressBar"
            :show-text="false"
            style="margin-top: 10px"
          ></el-progress>
        </h2>
        <el-table
          :data="report.problem_cameras"
          :empty-text='report.status === 2 ? "Все камеры перенесены успешно" : report.status_description'
          v-loading="loading"
          :element-loading-text="elementLoadingText">
        >
          <el-table-column label="Nimble_id" prop="nimble_id" >
            <template slot-scope="scope">
              <el-tooltip content="Перейти в ЛК Оператора" :open-delay="1000">
                <el-link type="primary" @click="goToLKOperator(scope.row, token)" class="link">
                  {{ scope.row.nimble_id }}
                </el-link>
              </el-tooltip>
            </template>
          </el-table-column>
          <!--<el-table-column label="видео до переноса" prop="video_main_status_before" />-->
          <el-table-column label="видео после переноса" prop="video_main_status_after">
            <template slot-scope="scope">
              <i v-if="scope.row.video_main_status_after === 0" class="el-icon-circle-close color_alarm"></i>
              <i v-if="scope.row.video_main_status_after === 1" class="el-icon-success color_good"></i>
            </template>
          </el-table-column>
          <el-table-column label="видео после ребута" prop="video_main_status_after_reboot">
            <template slot-scope="scope">
              <i v-if="scope.row.video_main_status_after_reboot == null && rebootInProgress" class="el-icon-loading"></i>
              <i v-if="scope.row.video_main_status_after_reboot === 0" class="el-icon-error color_alarm"></i>
              <i v-if="scope.row.video_main_status_after_reboot === 1" class="el-icon-success color_good"></i>
            </template>
          </el-table-column>
          <el-table-column label="аудио до переноса" prop="audio_main_status_before" />
          <el-table-column label="аудио после переноса" prop="audio_main_status_after" />
          <el-table-column label="аудио после ребута" prop="audio_main_status_after_reboot" />
        </el-table>
      </div>
    </main>
  </el-container>
</template>

<script>
import { mapActions, mapState } from "vuex";
import formatAppUrl from "@/core/helpers/formatAppUrl";
import Vue from "vue";
import { VK_OPERATOR_TOKEN_COOKIE_KEY } from "@/core/helpers/const";

const camerasRebootInProgressStatus = 6;
const transferInProgressStatus = 0;
const generatingReportStatus = 1;

export default {
  name: 'reserve-transfer-problems-report',
  computed: {
    ...mapState("reserveTransfers", ["report"]),
    ...mapState("auth", ["token"]),
  },
  data() {
    return {
      id: null,
      loading: true,
      rebootInProgress: false,
      isRebootButtonDisabled: false,
      elementLoadingText: "Подготавливается отчет",
      rebootCountdown: 0,
      progressBar: 0,
      notifyIds: [],
      previousStatus: 0,
      progressBarStep: 0.5
    };
  },
  created() {
    this.id = parseInt(this.$route.params.id)
    this.progressBar = 1
    this.getReport(this.id)
  },
  beforeDestroy () {
    clearInterval(this.intervalId)
    this.notifyIds.forEach((element) => element.close())
  },
  watch: {
    report () {
      if (this.report.enable_page_reloading === 1) {
        clearInterval(this.intervalId)
        this.intervalId = setInterval(() => {
          this.getReport(this.id)
        }, 3000)
      }
      if (this.report.enable_page_reloading === 0) {
        clearInterval(this.intervalId)
      }
      switch (this.report.status) {
        case transferInProgressStatus:
          this.loading = true
          this.progressBarStep = 2
          break
        case generatingReportStatus:
          this.loading = true
          this.progressBarStep = 0.5
          if (this.report.status !== this.previousStatus) {
            this.progressBar = 0
          }
          break
        default:
          this.loading = false
      }

      this.previousStatus = this.report.status

      this.rebootInProgress = this.report.status === camerasRebootInProgressStatus;

      this.elementLoadingText = this.report.status_description
    },
    rebootCountdown() {
      if (this.rebootCountdown > 0) {
        setTimeout(() => {this.rebootCountdown--}, 1000)
      }
    },
    progressBar () {
      if (this.loading) {
        if (this.progressBar < 100) {
          setTimeout(() => {
            this.progressBar += this.progressBarStep
          }, 500)
        } else {
          this.progressBar = 0
          setTimeout(() => {
            this.progressBar += this.progressBarStep
          }, 500)
        }
      }
    }
  },
  methods: {
    ...mapActions("reserveTransfers", {
      getReport: "GET_REPORT",
      rebootCameras: "REBOOT_CAMERAS"
    }),
    handleRebootCamerasButton(event){
      event.currentTarget.blur();
      this.isRebootButtonDisabled = true;

      this.rebootCameras(this.id)
        .then((response) => {
          if (response.success_message) {
            this.$notify({
              type: "success",
              offset: 60,
              duration: 5000,
              message: response.success_message,
            });
          }
          if (response.info_message) {
            setTimeout(() => {
              this.$notify({
                type: "info",
                offset: 60,
                duration: 5000,
                message: response.info_message
              })
            }, 100)
          }
          response.errors.forEach((error) => {
            setTimeout(() => {
              this.notifyIds.push(this.$notify({
                type: "warning",
                offset: 60,
                duration: 20000,
                message: error.message
              }))
            }, 100)
          })
        })
        .catch((e) => {
          this.$message({
            type: "error",
            message: e.response.data.error || "Неизвестная ошибка",
          });
        });

      setTimeout(() => {this.isRebootButtonDisabled = false}, 60000);
      this.rebootCountdown = 60;
    },
    goToLKOperator (item, token) {
      const url = `${formatAppUrl(item.operator_domain)}auth?operator=${item.id_operator}&redirectPath=cameras/edit/${item.id_camera}`;
      Vue.$cookies.set(VK_OPERATOR_TOKEN_COOKIE_KEY, token);
      window.open(url, '_blank');
    },
  },
};
</script>

<style>
[class^="color_"] {
  font-weight: bold;
}
.color_good {
  color: #4caf50;
}
.color_warning {
  color: #ff9800;
}
.color_alarm {
  color: #f44336;
}
</style>
